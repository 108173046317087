import React from "react";
import classnames from "classnames";
// import PropTypes from "prop-types";

const SelectFieldGroup = ({
  inputId,
  list,
  name,
  value,
  error,
  info,
  onChange,
  disabled,
  extraClassName,
}) => {
  inputId = inputId || "";
  return (
    <div className="form-group">
      <select
        inputId={inputId}
        className={classnames("form-control " + extraClassName, {
          "is-invalid": error,
        })}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {list.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

SelectFieldGroup.defaultProps = {
  inputId: "select",
  extraClassName: "",
  placeholder: "",
};

export default SelectFieldGroup;
