import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";

class FindSantaCruz extends Component {
  constructor() {
    super();
    this.state = {
      postcode: "",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", function () {
      var element = document.querySelector(".zip-img");
      if (element) {
        var position = element.getBoundingClientRect();

        // checking whether fully visible
        // if (position.top >= 0 && position.bottom <= window.innerHeight) {
        //   console.log("Element is fully visible in screen");
        // }

        // checking for partial visibility
        if (position.top < window.innerHeight && position.bottom >= 0) {
          window.$(".zip-img").addClass("animated-zip-img");
        }
      }
    });
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  searchByPostcode = () => {
    if (this.state.postcode !== "") {
      this.props.history.push("/store-locator?postcode=" + this.state.postcode);
    }
  };

  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <div className="zip-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 nlrp">
              {/* content.sec_8_img */}
              <div
                className="zip-img"
                style={{
                  background: `url(images/zip-bg-big.jpg)`,
                }}
              >
                {/* <Reveal>
                  <img src={content.sec_8_img} className="img-fluid" alt="" />
                </Reveal> */}
              </div>
            </div>
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div className="zip-cnt-sec">
                <Fade bottom duration={800}>
                  <h2>{content.sec_8_line_1}</h2>
                  <p>{content.sec_8_line_2}</p>
                  <div className="form-group col-md-8 nlrp">
                    <input
                      type="text"
                      name="postcode"
                      id="postcode"
                      value={this.state.postcode}
                      onChange={this.onChange}
                      className="form-control"
                      placeholder="Enter Zip Code"
                    />
                    <button
                      className="btn btn-default tbox-arrow"
                      onClick={this.searchByPostcode}
                    >
                      <img src="images/tbox-arrow.png" alt="" />
                    </button>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default withRouter(FindSantaCruz);
