import React, { Component } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Fade from "react-reveal/Fade";

class HomeProducts extends Component {
  render() {
    const { products, loading } = this.props;
    const override = css`
      display: block;
      margin: 0 auto;
    `;
    let contents = (
      <div className="sweet-loading">
        <ClipLoader
          css={override}
          size={25}
          color={"#3c3c3c"}
          loading={loading}
        />
      </div>
    );
    if (!loading) {
      const responsive = {
        0: {
          items: 1,
        },
        450: {
          items: 2,
        },
        600: {
          items: 3,
        },
        1000: {
          items: 3,
        },
      };
      contents = (
        <OwlCarousel
          className="owl-theme"
          margin={10}
          nav
          dots={false}
          responsive={responsive}
        >
          {products.map((product, index) => {
            return (
              <Fade bottom>
                <div className="item home-item" key={index}>
                  <Link to={`/product/${product.slug}`}>
                    <img
                      src={product.list_image}
                      className="img-fluid"
                      alt=""
                    />
                  </Link>
                  <div className="wwd-over">
                    <Link to={`/product/${product.slug}`}>
                      {product.product_name}
                    </Link>
                    <p>{product.info}</p>
                  </div>
                </div>
              </Fade>
            );
          })}
        </OwlCarousel>
      );
    }
    return (
      <div className="wwd">
        <div className="wwd-slider">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 nlrp">{contents}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeProducts;
