import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import Anchor from "./Anchor";

class CBDBanner extends Component {
  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <div className="cbdhead" style={{ background: content.sec_2_bg_color }}>
        {/* #ffcaa7 */}
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-6 cbd-right nlrp">
              <Reveal>
                <img src={content.sec_2_img} className="img-fluid" alt="" />
              </Reveal>
            </div>
            <div className="col-md-6">
              <div className="cbd-left">
                <Fade bottom duration={800}>
                  <div>
                    <h2>
                      {ReactHtmlParser(
                        content.sec_2_product_name.replace("\r\n", "")
                      )}
                    </h2>
                    <p>{content.sec_2_desc}</p>
                    <Anchor
                      linkType={content.sec_2_link_type}
                      link={content.sec_2_link}
                      text={content.sec_2_btn_title}
                    />
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default CBDBanner;
