import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

export default class OurProcess extends Component {
  render() {
    const { about } = this.props;
    return (
      <>
        <div className="row mt50">
          <div className="col-md-12">{ReactHtmlParser(about.para_1)}</div>
        </div>
        <div className="row abt-pro-sc">
          <div className="col-md-3">
            <div className="opro-img">
              <img src={about.img_1} alt="" />
              <p>{about.img_1_name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="opro-img">
              <img src={about.img_2} alt="" />
              <p>{about.img_2_name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="opro-img">
              <img src={about.img_3} alt="" />
              <p>{about.img_3_name}</p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="opro-img">
              <img src={about.img_4} alt="" />
              <p>{about.img_4_name}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">{ReactHtmlParser(about.para_2)}</div>
        </div>
      </>
    );
  }
}
