import axios from "axios";

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const setStateIdHeader = (state_id) => {
  if (state_id) {
    axios.defaults.headers.common["state-id"] = state_id;
  } else {
    delete axios.defaults.headers.common["state-id"];
  }
};

export { setAuthToken, setStateIdHeader };
