import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import TopNav from "../includes/TopNav";
import Footer from "../includes/Footer";
import axios from "axios";
import { API_URL } from "../../store/actions/types";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";

class ProductDetail extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      product: [],
      quantity: 1,
      items: 0,
      cart: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.match.params.slug) {
      axios
        .post(`${API_URL}/product-detail/${this.props.match.params.slug}`)

        .then((response) => {
          if (response.data.success === "true") {
            this.setState({
              product: response.data.product,
              loading: false,
            });
          }
        })
        .then((result) => {
          if (!localStorage.getItem("SantaCruzCart")) {
            localStorage.setItem(
              "SantaCruzCart",
              JSON.stringify(this.state.cart)
            );
          } else
            this.setState({
              cart: JSON.parse(localStorage.getItem("SantaCruzCart")),
            });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  }

  addToCart = async (
    id,
    productName,
    productSlug,
    productInfo,
    productAvatar,
    price
  ) => {
    const { cart } = this.state;
    var tempCart = [];
    var itemFound = false;

    tempCart = cart.map((cartItem) => {
      if (cartItem.id === id) {
        itemFound = true;
        return {
          ...cartItem,
          quantity: cartItem.quantity + 1,
        };
      } else {
        return cartItem;
      }
    });

    if (itemFound) {
      await this.setState({
        cart: tempCart,
      });
    } else {
      await this.setState({
        cart: [
          ...this.state.cart,
          {
            id,
            productName,
            productSlug,
            productInfo,
            productAvatar,
            quantity: this.state.quantity,
            price,
          },
        ],
      });
    }

    localStorage.setItem("SantaCruzCart", JSON.stringify(this.state.cart));
    this.props.history.push("/cart");
  };

  render() {
    const { product, loading } = this.state;
    const override = css`
      display: block;
      margin: 100px auto;
    `;
    // const responsive = {
    //   0: {
    //     items: 1,
    //   },
    //   450: {
    //     items: 1,
    //   },
    //   600: {
    //     items: 1,
    //   },
    //   1000: {
    //     items: 1,
    //   },
    // };
    return (
      <div>
        <TopNav />
        <ClipLoader
          css={override}
          size={25}
          color={"#3c3c3c"}
          loading={loading}
        />
        {!loading ? (
          <Fade duration={1800}>
            <div className="pro-details main-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cross-det cross-det-prd">
                      <Link
                        to={`/products/${product.cat_slug}/${product.subcat_slug}`}
                      >
                        x
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pd-carousel">
                      {/* <OwlCarousel
                    className="owl-theme"
                    loop
                    margin={10}
                    responsive={responsive}
                  >
                    <div className="item">
                      <img src={product.avatar} className="img-fluid" alt="" />
                    </div>
                    <div className="item">
                      <img src={product.list_image} className="img-fluid" alt="" />
                    </div>
                  </OwlCarousel> */}
                      <div className="pro-det-img">
                        <img
                          src={product.avatar}
                          className="img-fluid"
                          alt=""
                        />
                        <p>{product.info}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pro-det-in">
                      <nav>
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={`/${product.cat_slug}`}>
                              {product.category_name}
                            </Link>
                          </li>
                          <li className="breadcrumb-item active">
                            {product.product_name}
                          </li>
                        </ol>
                      </nav>
                    </div>
                    <div className="pro-det-cnt">
                      <div className="row">
                        <div className="col-md-12">
                          <h3>{product.product_name}</h3>
                          <p>{ReactHtmlParser(product.short_description)}</p>
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pro-ing">
                            <p style={{ marginBottom: "20px" }}>
                              {ReactHtmlParser(product.details)}
                            </p>
                            <p style={{ marginBottom: "20px" }}>
                              {product.product_options &&
                              product.product_options.length > 0
                                ? "Available in: " +
                                  product.product_options.map(
                                    (product, index) => {
                                      return index === 0
                                        ? product.option_name
                                        : " " + product.option_name;
                                    }
                                  )
                                : null}
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* product.is_sellable === 0 */}
                      {product.is_sellable === 0 ? (
                        <div className="row">
                          {/* <div className="col-md-6 nlrp">
                        <div className="pro-det-btn2">
                          <div className="pro-dt-in">
                            <div className="select-box">
                              <div className="select-box__current" tabIndex={1}>
                                <div className="select-box__value">
                                  <input
                                    className="select-box__input"
                                    type="radio"
                                    id={0}
                                    defaultValue={1}
                                    name="Ben"
                                    defaultChecked="checked"
                                  />

                                  <p className="select-box__input-text">
                                    {product.product_options[0].option_name}
                                  </p>
                                </div>
                                <img
                                  className="select-box__icon"
                                  src="images/img_295694.svg"
                                  alt="Arrow Icon"
                                  aria-hidden="true"
                                  alt=""
                                />
                              </div>
                              <ul className="select-box__list">
                                {product.product_options
                                  ? product.product_options.map(
                                      (product, index) => {
                                        return (
                                          <li key={index}>
                                            <label
                                              className="select-box__option"
                                              aria-hidden="aria-hidden"
                                            >
                                              {product.option_name}
                                            </label>
                                          </li>
                                        );
                                      }
                                    )
                                  : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div> */}
                          <div className="col-md-12">
                            <div className="pro-det-btn2">
                              <div className="pro-dt-in-btn">
                                <Link to={`/store-locator/${product.id}`}>
                                  Where to buy
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : this.props.setting.showCart ? (
                        <div className="pro-det-btn">
                          <Link
                            onClick={() =>
                              this.addToCart(
                                product.id,
                                product.product_name,
                                product.slug,
                                product.info,
                                product.avatar,
                                product.price
                              )
                            }
                            replace
                          >
                            Add to cart - ${product.price}
                          </Link>
                        </div>
                      ) : (
                        <div className="pro-det-btn2">
                          <div className="pro-dt-in-btn">
                            <Link to={`/store-locator/${product.id}`}>
                              Where to buy
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </Fade>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.setting,
});

export default connect(mapStateToProps)(withRouter(ProductDetail));
