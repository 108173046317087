import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import PropTypes from "prop-types";

const AgeRoute = ({ component: Component, age, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // return <Component {...props} />;
      if (age.verified && age.eligible) {
        return <Component {...props} />;
      } else {
        return <Redirect to="/verify-age" />;
      }
    }}
  />
);

const mapStateToProps = (state) => ({
  age: state.age,
});
export default connect(mapStateToProps)(AgeRoute);
