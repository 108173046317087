import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { setStateId } from "../../store/actions/stateActions";
import { setSetting } from "../../store/actions/settingActions";
import { API_URL } from "../../store/actions/types";
import { connect } from "react-redux";
import MobileNav from "./MobileNav";
import tawkTo from "tawkto-react";

// $2y$10$SYwSwA.ysalp9RUl4MD5MeJMx0gDPR5qhOFzJSWKwQM5nEu2Pn3xS

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      states: [],
      loading: true,
      cart_products: 0,
      show_cart: false,
      show_login: false,
    };
  }
  componentDidMount() {
    tawkTo("5ec2f4188ee2956d73a25acb");
    const $ = window.$;
    $("#navBarToggler").click(function () {
      if ($(this).hasClass("open")) {
        $(this).removeClass("open");
        $("#navbarMobile").fadeOut();
        // $("body").css({ overflow: "auto" });
      } else {
        $(this).addClass("open");
        // $("body").css({ overflow: "hidden" });
        $("#navbarMobile").fadeIn();
      }
    });
    if ($(window).width() > 991) {
      $(".header-sticky").addClass("sticky");
    }
    $(".nav-placeholder").show();
    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop >= 0) {
        //70
        // $(".nav-placeholder").show();
        if ($(window).width() > 991) {
          $(".header-sticky").addClass("sticky");
        }
        // $("#nav-tabContent").addClass("mt68");
        // $(".main-title").addClass("main-heading-mt");
        $(".udb-page").addClass("udb-page-height");
        // $(".pro-listing .gallery").addClass("mb95");
        // $(".vape-tech-tp").addClass("mt15m");
        // $(".edi-process").addClass("mt44");
      } else {
        // $(".nav-placeholder").hide();
        if ($(window).width() > 991) {
          // $(".header-sticky").removeClass("sticky");
        }
        // $("#nav-tabContent").removeClass("mt68");
        // $(".main-title").removeClass("main-heading-mt");
        $(".udb-page").removeClass("udb-page-height");
        // $(".pro-listing .gallery").removeClass("mb95");
        // $(".vape-tech-tp").removeClass("mt15m");
        // $(".edi-process").removeClass("mt44");
      }
    });

    this.updateCartIcon();

    axios(`${API_URL}/all-categories?withStates`)
      .then(async (response) => {
        if (response.data.success === "true") {
          await this.setState({
            categories: response.data.categories,
            states: response.data.states,
            loading: false,
            show_cart: response.data.header.show_cart,
            show_login: response.data.header.show_login,
          });
          await this.props.setSetting(
            response.data.header.show_cart,
            response.data.header.show_login,
            response.data.footer
          );
        }
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          loading: false,
        });
        alert("Something went wrong.");
      });
  }

  closeMobileNav = () => {
    window.$("#navbarMobile").fadeOut();
    // window.$("body").css({ overflow: "auto" });
  };

  updateCartIcon = async () => {
    var cart = [];
    if (
      localStorage.getItem("SantaCruzCart") &&
      JSON.parse(localStorage.getItem("SantaCruzCart")).length > 0
    ) {
      cart = JSON.parse(localStorage.getItem("SantaCruzCart"));
    }
    await this.setState({
      cart_products: cart.length,
    });
  };

  selectState = async (stateId, stateName) => {
    await this.props.setStateId(stateId, stateName);
    await localStorage.setItem("ageCheck", true);
    window.location.reload();
  };

  render() {
    const { auth, usState } = this.props;
    const {
      categories,
      states,
      loading,
      cart_products,
      show_cart,
      show_login,
    } = this.state;
    return (
      <>
        <div className="nav-placeholder"></div>
        <div
          className="header header-sticky"
          style={{
            position: this.props.position || "unset",
          }}
        >
          <MobileNav
            categories={categories}
            cartProducts={cart_products}
            states={states}
          />
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <Link className="navbar-brand" to="/">
                    <img
                      src="/images/SantaCruzLogo.png"
                      className="img-fluid logo-norm"
                      alt=""
                    />
                    <img
                      // src="/images/logo-sc-res.jpg"
                      src="/images/SantaCruzLogo.png"
                      className="img-fluid logo-res"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="col-md-9">
                  <ul className="navbar-nav ml-auto state-mob-menu">
                    <li className="nav-item dropdown dd-state">
                      <Link
                        className="nav-link nav-link-animate nav-link-dd"
                        id="navbarDropdown"
                        data-toggle="dropdown"
                      >
                        {/* <i
                          className="fa fa-location-arrow"
                          aria-hidden="true"
                        /> */}
                        {usState.stateId > 0
                          ? usState.stateName
                          : "Select State"}
                        {/* <img
                          src="/images/dd-icon.png"
                          className="dd-icon"
                          alt=""
                        /> */}
                        <i className="fa fa-caret-down" aria-hidden="true" />
                      </Link>
                      <div
                        className="dropdown-menu state-mob-submenu"
                        aria-labelledby="navbarDropdown"
                      >
                        {states.map((state, index) => {
                          return state.id !== usState.stateId ? (
                            <Link
                              key={index}
                              onClick={() =>
                                this.selectState(state.id, state.state_code)
                              }
                              className="dropdown-item"
                            >
                              {state.state_name}
                            </Link>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                  {/* <button className="navbar-toggler" type="button">
                    <span className="navbar-toggler-icon" />
                  </button> */}
                  <div className="nav-icon-btn" id="navBarToggler">
                    <div></div>
                  </div>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarResponsive"
                  >
                    <ul className="navbar-nav ml-auto">
                      {/* <div className="sweet-loading">
                      <ClipLoader
                        css={override}
                        size={25}
                        color={"#3c3c3c"}
                        loading={loading}
                      />
                    </div> */}

                      {!loading ? (
                        <>
                          <li className="nav-item dropdown dd-state">
                            <Link
                              className="nav-link nav-link-animate nav-link-dd"
                              id="navbarDropdown"
                              data-toggle="dropdown"
                            >
                              {/* <i
                          className="fa fa-location-arrow"
                          aria-hidden="true"
                        /> */}
                              {usState.stateId > 0
                                ? usState.stateName
                                : "Select State"}
                              <img
                                src="/images/dd-icon.png"
                                className="profile-icon"
                                alt=""
                                style={{ width: "12px", marginTop: "-4px" }}
                              />
                            </Link>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              {states.map((state, index) => {
                                return state.id !== usState.stateId ? (
                                  <Link
                                    key={index}
                                    onClick={() =>
                                      this.selectState(
                                        state.id,
                                        state.state_code
                                      )
                                    }
                                    className="dropdown-item"
                                  >
                                    {state.state_name}
                                  </Link>
                                ) : (
                                  ""
                                );
                              })}
                            </div>
                          </li>
                          <li className="nav-item dropdown">
                            {/*  mob-cats */}
                            <Link
                              className="nav-link nav-link-animate"
                              id="navbarPrdDropdown"
                              data-toggle="dropdown"
                            >
                              Products
                              <img
                                src="/images/dd-icon.png"
                                className="dd-icon"
                                alt=""
                              />
                            </Link>
                            <div className="dropdown-menu">
                              {categories.map((category, index) => {
                                return (
                                  <Link
                                    key={index}
                                    replace
                                    to={`/products/${category.slug}`}
                                    className="dropdown-item"
                                  >
                                    {category.category_name}
                                  </Link>
                                );
                              })}
                            </div>
                          </li>

                          {/* {categories.map((category, index) => {
                            return (
                              <li className="nav-item desktop-cats" key={index}>
                                <Link
                                  replace
                                  to={`/${category.slug}`}
                                  className="nav-link nav-link-animate"
                                >
                                  {category.category_name}
                                </Link>
                              </li>
                            );
                          })} */}
                          <li className="nav-item">
                            <Link
                              to="/about"
                              className="nav-link nav-link-animate"
                            >
                              About
                            </Link>
                          </li>
                          {/* {!auth.isAuthenticated ? (
                      <li className="nav-item">
                        <Link to="/login" className="nav-link">
                          Login
                        </Link>
                      </li>
                    ) : null} */}

                          <li className="nav-item lnk-store">
                            <Link
                              to="/store-locator"
                              className="nav-link nav-link-animate"
                            >
                              {/* <i
                          className="fa fa-location-arrow"
                          aria-hidden="true"
                        /> */}
                              Store Locator
                            </Link>
                          </li>
                          {show_cart ? (
                            <li className="nav-item">
                              <Link
                                to="/cart"
                                className="nav-link nav-cart nav-icon"
                              >
                                <img
                                  className="icon-cart"
                                  src={`/images/${
                                    cart_products > 0
                                      ? "cart-full.png"
                                      : "cart-icon.png"
                                  }`}
                                  alt=""
                                />
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                          {show_login ? (
                            <li className="nav-item">
                              <Link
                                to={
                                  !auth.isAuthenticated
                                    ? "/login"
                                    : "/my-orders"
                                }
                                className="nav-link nav-cart nav-icon"
                              >
                                <img
                                  src={
                                    !auth.isAuthenticated
                                      ? "/images/sign-icon.png"
                                      : "/images/profile-icon.png"
                                  }
                                  alt=""
                                  style={{
                                    width: !auth.isAuthenticated
                                      ? "28px"
                                      : "22px",
                                  }}
                                />
                              </Link>
                            </li>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  usState: state.usState,
});
const mapDispatchToProps = { setStateId, setSetting };
export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
