import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import Anchor from "./Anchor";

class LightSativa extends Component {
  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <div className="p-sec">
        <div className="container-fluid">
          <div className="row" style={{ background: content.sec_4_bg_color }}>
            <div className="col-md-6 nlrp">
              <div className="pro-img">
                <Reveal>
                  <img src={content.sec_4_img} className="img-fluid" alt="" />
                </Reveal>
              </div>
            </div>
            <div className="col-md-6 nlrp">
              <div className="p1-cnt">
                <Fade bottom duration={800}>
                  <div>
                    <h1>{content.sec_4_line_1}</h1>
                    <p>
                      {ReactHtmlParser(
                        content.sec_4_line_2.replace("\r\n", "")
                      )}
                    </p>
                    <p>
                      <span>{ReactHtmlParser(content.sec_4_breadcrumb)}</span>
                    </p>
                    <p>
                      <Anchor
                        linkType={content.sec_4_link_type}
                        link={content.sec_4_link}
                        text={content.sec_4_btn_title}
                      />
                    </p>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default LightSativa;
