import React, { Component } from "react";
import { connect } from "react-redux";

class UserHeader extends Component {
  render() {
    const { auth } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 usr-sec">
            <h4>User Dashboard</h4>
          </div>
          <div className="col-md-6 usr-sec">
            <div className="udb-main-pro">
              <div className="udb-main-pro-img">
                <img src={auth.user.avatar} className="img-fluid" alt="" />
              </div>
              <div className="udb-main-pro-name">
                <p>{auth.user.fullName}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, null)(UserHeader);
