import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Anchor from "./Anchor";
import axios from "axios";
import { API_URL } from "../../store/actions/types";

class Header extends Component {
  componentDidMount() {
    axios(API_URL + "/get-shipping-fees")
      .then((response) => {
        if (response.data.success) {
          this.setState({ shippingAndHandling: response.data.shipping_fee });
        } else {
          console.log("Could not get shipping fees.");
        }
      })
      .catch((e) => console.log("Something went wrong"));
  }
  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <header
        className="masthead"
        style={{
          backgroundImage: `url(${
            window.innerWidth <= 767
              ? content.sec_1_mob_bg_img || ""
              : content.sec_1_bg_img
          })`,
        }}
      >
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-md-8 header-item">
              <Fade bottom duration={800}>
                <div>
                  <h2>
                    {ReactHtmlParser(content.sec_1_line_1.replace("\r\n", ""))}
                  </h2>
                  <p>{content.sec_1_line_2}</p>
                  <p>
                    <Anchor
                      linkType={content.sec_1_link_type}
                      link={content.sec_1_link}
                      text={content.sec_1_btn_title}
                    />
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </header>
    ) : (
      ""
    );
  }
}

export default Header;
