import axios from "axios";
import { GET_ERRORS, API_URL } from "./types";

export const submitContactUs = (
  data,
  clearFields,
  startLoading,
  stopLoading
) => async (dispatch) => {
  startLoading();
  axios
    .post(API_URL + "/contact-us", data)
    .then((res) => {
      stopLoading();
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      if (res.data.success === "true") {
        clearFields();
      }
      dispatch({
        type: GET_ERRORS,
        payload: {
          error: {
            success_message: "Your contact us form has been submitted.",
          },
        },
      });
    })
    .catch((err) => {
      stopLoading();
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
