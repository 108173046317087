export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ADD_PRODUct_TO_CART = "ADD_PRODUCT_TO_CART";
export const ADD_TO_STATE_CART = "ADD_TO_STATE_CART";
export const GET_INSTRUCTION = "GET_INSTRUCTION";
export const SET_AGE_VERIFICATION = "SET_AGE_VERIFICATION";
export const SET_REDIRECT_TO = "SET_REDIRECT_TO";
export const SET_STATE_ID = "SET_STATE_ID";
export const SET_SETTING = "SET_SETTING";
export const API_URL = "https://santacruzbrands.com/santa-cruz/api/public";
// export const API_URL = "http://localhost/SantaCruz/api/public";
// export const API_URL = "https://santacruz.clickysoft.us/santa-cruz/api/public";
