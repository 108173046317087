import axios from "axios";
import { GET_ERRORS, GET_INSTRUCTION, API_URL } from "./types";

export const updateInstruction = (instruction) => (dispatch) => {
  dispatch({
    type: GET_INSTRUCTION,
    payload: instruction,
  });
};

export const submitOrder = (data, history, startStopLoading) => (dispatch) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/order-confirm", data)
    .then((res) => {
      startStopLoading(false);
      if (res.data.success === "true") {
        dispatch({
          type: GET_INSTRUCTION,
          payload: "",
        });
        localStorage.removeItem("SantaCruzCart");
        history.push("/thankyou/" + res.data.order_no);
      } else {
        alert("Something went wrong");
      }
    })
    .catch((err) => {
      startStopLoading(false);
      if (err.response && err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};
