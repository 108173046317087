import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopNav from "../includes/TopNav";
import Footer from "../includes/Footer";
import axios from "axios";
import { API_URL } from "../../store/actions/types";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Fade from "react-reveal/Fade";
import ReactHtmlParser from "react-html-parser";
import { deviceType } from "react-device-detect";

class ProductsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainCat: this.props.match.params.maincat,
      mainTab: this.props.match.params.tab || "all",
      mainCatHeading: "",
      mainCatSubHeading: "",
      mainCatHeaderImage: "",
      mainCatMobileHeaderImage: "",
      heading: "",
      subHeading: "",
      headerImage: "",
      mobileHeaderImage: "",
      subCategories: [],
      products: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.myComponentDidMount();
  }
  componentWillReceiveProps = (nextProps, nextState) => {
    if (nextProps.match.params.maincat !== this.state.mainCat) {
      this.setState(
        {
          mainCat: nextProps.match.params.maincat,
          mainTab: nextProps.match.params.tab || "all",
          heading: "",
          subHeading: "",
          headerImage: "",
          mobileHeaderImage: "",
          subCategories: [],
          products: [],
          loading: true,
        },
        () => {
          this.myComponentDidMount();
        }
      );
    }
  };

  myComponentDidMount = () => {
    window.scrollTo(0, 0);
    window.$("#navBarToggler").removeClass("open");
    window.$("#navbarMobile").fadeOut();
    const mainCat = this.state.mainCat;
    axios
      .post(`${API_URL}/all-product/${mainCat}`)
      .then((response) => {
        if (response.data.success === "true") {
          var products = this.getAllProducts(response.data.sub_categories);

          this.setState({
            mainCat,
            mainCatHeading: response.data.heading,
            mainCatSubHeading: ReactHtmlParser(response.data.sub_heading),
            mainCatHeaderImage: response.data.cat_header_img,
            mainCatMobileHeaderImage: response.data.cat_header_img_mobile,
            heading: response.data.heading,
            subHeading: ReactHtmlParser(response.data.sub_heading),
            headerImage: response.data.cat_header_img,
            mobileHeaderImage: response.data.cat_header_img_mobile,
            subCategories: response.data.sub_categories,
            products,
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  };

  getAllProducts = (subCategories) => {
    var products = [];
    subCategories.map((category) => {
      for (var i = 0; i < category.product.length; i++) {
        products.push({
          title: category.product[i].product_name,
          avatar: category.product[i].avatar,
          info: category.product[i].info,
          slug: category.product[i].slug,
        });
      }
      return "";
    });
    return products;
  };
  changeProducts = (index) => {
    var products = [],
      mainTab = "",
      heading = "",
      subHeading = "",
      headerImage = "",
      mobileHeaderImage = "";

    if (index === -1) {
      products = this.getAllProducts(this.state.subCategories);
      mainTab = "all";
      heading = this.state.mainCatHeading;
      subHeading = this.state.mainCatSubHeading;
      headerImage = this.state.mainCatHeaderImage;
      mobileHeaderImage = this.state.mainCatMobileHeaderImage;
    } else {
      products = this.state.subCategories[index].product.map((product) => ({
        title: product.product_name,
        avatar: product.avatar,
        info: product.info,
        slug: product.slug,
      }));

      mainTab = this.state.subCategories[index].slug;

      heading = this.state.subCategories[index].category_name;
      subHeading = this.state.subCategories[index].description;
      headerImage = this.state.subCategories[index].header_img;
      mobileHeaderImage = this.state.subCategories[index].header_img_mobile;
    }
    this.setState({ products: [], mainTab }, () => {
      this.setState({
        products,
        heading,
        subHeading,
        headerImage,
        mobileHeaderImage,
      });
    });
  };
  render() {
    const {
      loading,
      headerImage,
      mobileHeaderImage,
      heading,
      subHeading,
      subCategories,
      products,
      mainTab,
    } = this.state;
    const override = css`
      display: block;
      margin: 100px auto;
    `;
    return (
      <div className="product-list">
        <TopNav />
        {!loading ? (
          <Fade duration={1800}>
            <div className="prd-header">
              {deviceType === "mobile" ? (
                <img src={mobileHeaderImage} className="img-fluid" alt="" />
              ) : (
                <img src={headerImage} className="img-fluid" alt="" />
              )}

              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="cat-heading">{heading}</h4>
                    <span>{subHeading}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="tab-nav">
                      <li>
                        <Link
                          onClick={() => this.changeProducts(-1)}
                          className={mainTab === "all" ? "active" : ""}
                        >
                          All
                        </Link>
                      </li>
                      {subCategories.map((category, index) => {
                        return (
                          <li key={index}>
                            <Link
                              onClick={() => this.changeProducts(index)}
                              className={
                                mainTab === category.slug ? "active" : ""
                              }
                            >
                              {category.category_name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="row row-product">
                  {products.map((product, index) => (
                    <div
                      key={index}
                      className="gallery_product col-lg-4 col-md-4 col-sm-6 col-6 animateToTop text-center"
                    >
                      <Link
                        to={`/product/${product.slug}`}
                        className="product-title"
                      >
                        <img
                          src={product.avatar}
                          className="img-fluid img-product"
                          alt=""
                        />
                        <br />
                        {product.title}
                      </Link>
                      <p className="info">{product.info}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Footer />
          </Fade>
        ) : (
          <div className="sweet-loading">
            <ClipLoader
              css={override}
              size={25}
              color={"#3c3c3c"}
              loading={loading}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ProductsList;
