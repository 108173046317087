import React from "react";
import classnames from "classnames";

const TextFieldGroup = ({
  autoComplete,
  name,
  id,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  readOnly,
  extraClassName,
}) => {
  return (
    <div className={`form-group${" " + extraClassName}`}>
      <input
        type={type}
        className={classnames("form-control", {
          "is-invalid": error,
        })}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && (
        <div className="invalid-feedback">
          <small>{error}</small>
        </div>
      )}
    </div>
  );
};

TextFieldGroup.defaultProps = {
  id: "",
  type: "text",
  extraClassName: "",
  autoComplete: "on",
  readOnly: false,
  disabled: false,
};

export default TextFieldGroup;
