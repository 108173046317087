import React from "react";
import classnames from "classnames";

const TextField = ({
  autoComplete,
  name,
  placeholder,
  value,
  error,
  info,
  type,
  onChange,
  disabled,
  readOnly,
  extraClassName,
}) => {
  return (
    <div
      className={`form-group wrap-input100 rs1-wrap-input100 validate-input m-b-20 ${extraClassName}`}
    >
      <input
        type={type}
        className={classnames("form-control input100", {
          "is-invalid": error,
        })}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

TextField.defaultProps = {
  type: "text",
  extraClassName: "",
  autoComplete: "on",
  readOnly: false,
  disabled: false,
};

export default TextField;
