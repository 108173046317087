import React, { Component } from "react";

class Careers extends Component {
  render() {
    const { career } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 abt-career">
          <p>
            {career[0].desc}{" "}
            <a href={`mailto:${career[0].email}`}>{career[0].email}</a>
          </p>
        </div>
      </div>
    );
  }
}

export default Careers;
