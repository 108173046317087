// clickys_santa
// kjZhqCUCLdKi
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";

import { SET_AGE_VERIFICATION, SET_STATE_ID } from "./store/actions/types";
import { setAuthToken, setStateIdHeader } from "./functions/setHeaders";
import { setCurrentUser, logoutUser } from "./store/actions/authActions";

import "./App.css";
import "./slider-transition.scss";
import store from "./store";
//User Route
import UserRoute from "./components/includes/UserRoute";
//Age verified Route
import AgeRoute from "./components/includes/AgeRoute";
//Components
import Home from "./components/Home";
// import Edibles from "./components/Edibles";
// import Vapes from "./components/Vapes";
import About from "./components/About";
import StoreLocator from "./components/StoreLocator.js";
// import StoreLocatorNew from "./components/StoreLocatorNew.js";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ShoppingCart from "./components/ShoppingCart";
import Login from "./components/Login";
import Register from "./components/Register";
import Checkout from "./components/Checkout";
import Dashboard from "./components/Dashboard";
import MyOrders from "./components/MyOrders";
import OrderDetails from "./components/OrderDetails";
import MyProfile from "./components/MyProfile";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import ProductsList from "./components/Prdoducts/ProductsList";
import ProductDetail from "./components/Prdoducts/ProductDetail";
import Thankyou from "./components/Thankyou";
import VerifyAge from "./components/VerifyAge";
import NotEligible from "./components/NotEligible";
// import { TransitionGroup, CSSTransition } from "react-transition-group";

// Check for token
if (localStorage.jwtToken) {
  // Set Auth token header auth
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);

  //Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    window.location.href = "/";
  }
}
// Check for age confirmation
// if (localStorage.getItem("SantaCruzAgeVerified")) {
//   const ageVerified = JSON.parse(localStorage.getItem("SantaCruzAgeVerified"));
//   store.dispatch({
//     type: SET_AGE_VERIFICATION,
//     payload: ageVerified,
//   });
// }
if (localStorage.getItem("ageCheck")) {
  localStorage.removeItem("ageCheck");
  const ageVerified = JSON.parse(localStorage.getItem("SantaCruzAgeVerified"));
  store.dispatch({
    type: SET_AGE_VERIFICATION,
    payload: ageVerified,
  });
}

// Check for state selection
if (localStorage.getItem("SantaCruzUsState")) {
  const usState = JSON.parse(localStorage.getItem("SantaCruzUsState"));
  setStateIdHeader(usState.stateId);
  store.dispatch({
    type: SET_STATE_ID,
    payload: usState,
  });
}
class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     prevDepth: this.getPathDepth(this.props.location),
  //   };
  // }

  // componentWillReceiveProps() {
  //   this.setState({ prevDepth: this.getPathDepth(this.props.location) });
  // }

  // getPathDepth(location) {
  //   let pathArr = [];
  //   location.pathname.split("/");
  //   pathArr = pathArr.filter((n) => n !== "");
  //   return pathArr.length;
  // }
  render() {
    // const { location } = this.props;
    // const currentKey = location.pathname.split("/")[1] || "/";
    // const timeout = { enter: 800, exit: 400 };
    return (
      // <TransitionGroup component="div" className="App">
      //   <CSSTransition
      //     key={currentKey}
      //     timeout={timeout}
      //     classNames="pageSlider"
      //     // fade
      //     mountOnEnter={false}
      //     unmountOnExit={true}
      //   >
      //     <div
      //       className={
      //         this.getPathDepth(location) - this.state.prevDepth >= 0
      //           ? "left"
      //           : "right"
      //       }
      //     >

      //  location={location}
      <Provider store={store}>
        <Router>
          <Switch>
            <AgeRoute exact path="/" component={Home} />
            <Route exact path="/verify-age" component={VerifyAge} />
            <Route exact path="/not-eligible" component={NotEligible} />
            {/* <AgeRoute exact path="/vape-pens/:tab?" component={Vapes} /> */}
            <AgeRoute exact path="/about/:sub_page?" component={About} />
            <AgeRoute
              exact
              path="/store-locator/:id?"
              component={StoreLocator}
            />
            {/* <Route
              exact
              path="/store-locator-new/:id?"
              component={StoreLocatorNew}
            /> */}
            <AgeRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            <AgeRoute exact path="/cart" component={ShoppingCart} />
            <UserRoute exact path="/checkout" component={Checkout} />
            <AgeRoute exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/reset-password/:reset_code"
              component={ResetPassword}
            />
            <AgeRoute exact path="/register" component={Register} />
            <Route exact path="/thankyou/:order_no" component={Thankyou} />
            <AgeRoute
              exact
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />

            <UserRoute exact path="/dashboard" component={Dashboard} />
            <UserRoute exact path="/my-orders" component={MyOrders} />
            <UserRoute
              exact
              path="/order-details/:order_no"
              component={OrderDetails}
            />
            <UserRoute exact path="/my-profile" component={MyProfile} />
            <UserRoute
              exact
              path="/change-password"
              component={ChangePassword}
            />
            <AgeRoute exact path="/product/:slug" component={ProductDetail} />
            {/* <AgeRoute
              exact={false}
              path="/prd/:maincat/:tab?"
              component={Edibles}
            /> */}
            <AgeRoute
              exact={false}
              path="/products/:maincat/:tab?"
              component={ProductsList}
            />
          </Switch>
        </Router>
      </Provider>
      //     </div>
      //   </CSSTransition>
      // </TransitionGroup>
    );
  }
}

export default App;
// export default withRouter(App);
