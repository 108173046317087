import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setStateId } from "../../store/actions/stateActions";

class MobileNav extends Component {
  constructor() {
    super();
    this.state = { navOpen: "" };
  }
  componentDidMount() {
    window.$("#nav-icon2").click(function () {
      window.$(this).toggleClass("open");
    });
  }
  closeMobileNav = () => {
    window.$("#navbarMobile").fadeOut();
  };
  selectState = async (stateId, stateName) => {
    await this.props.setStateId(stateId, stateName);
    await localStorage.setItem("ageCheck", true);
    window.location.reload();
  };
  toggleMenu = () => {
    if (this.state.display === "none") {
      this.setState({ display: "block", navOpen: " open" });
    } else {
      this.setState({ display: "none", navOpen: "" });
    }
  };

  render() {
    const { auth, categories, cartProducts, setting } = this.props;
    const { navOpen } = this.state;
    return (
      <>
        <div className="collapse navbar-collapse" id="navbarMobile">
          {/* <div className="mobile-nav-header">
            <div className="mobile-icon-nav">
              <ul>
                <li>
                  <Link to="/cart">
                    <img
                      className="icon-cart"
                      src={`/images/${
                        cartProducts > 0 ? "cart-product.png" : "cart.png"
                      }`}
                      alt=""
                    />
                  </Link>
                </li>
                <li>
                  <Link to={!auth.isAuthenticated ? "/login" : "/my-orders"}>
                    <img
                      src={
                        !auth.isAuthenticated
                          ? "/images/sign-in-btn.png"
                          : "/images/db-icon.png"
                      }
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className={`mobile-nav-links${navOpen}`}>
            <ul>
              {/* <li className="mob-nav-topline"></li> */}
              {categories.map((category, index) => {
                return (
                  <li key={index}>
                    <Link to={`/products/${category.slug}`}>
                      {category.category_name}
                    </Link>
                  </li>
                );
              })}
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/store-locator">Store Locator</Link>
              </li>
              {setting.showLogin ? (
                <li>
                  <Link to={!auth.isAuthenticated ? "/login" : "/my-orders"}>
                    {!auth.isAuthenticated ? "Login" : "My Account"}
                    {/* <img
                      src={
                        !auth.isAuthenticated
                          ? "/images/sign-in-btn.png"
                          : "/images/db-icon.png"
                      }
                      alt=""
                    /> */}
                  </Link>
                </li>
              ) : (
                ""
              )}
              {setting.showCart ? (
                <li>
                  <Link to="/cart">
                    Cart {cartProducts > 0 ? `(${cartProducts})` : ""}
                    {/* <img
                      className="icon-cart"
                      src={`/images/${
                        cartProducts > 0 ? "cart-product.png" : "cart.png"
                      }`}
                      alt=""
                    /> */}
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  usState: state.usState,
  setting: state.setting,
});
export default connect(mapStateToProps, { setStateId })(MobileNav);
