import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { submitContactUs } from "../../store/actions/contactusActions";
import TextFieldGroup from "./TextFieldGroup";
import TextAreaGroup from "./TextAreaGroup";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
      errors: {},
      loading: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone: this.state.phone,
      subject: this.state.subject,
      message: this.state.message,
    };
    this.props.submitContactUs(
      data,
      this.clearFields,
      this.startLoading,
      this.stopLoading
    );
  };

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  clearFields = () => {
    this.setState({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };

  render() {
    const { contact } = this.props;
    const {
      errors,
      first_name,
      last_name,
      email,
      phone,
      subject,
      message,
      loading,
    } = this.state;
    const override = css`
      display: inline-block;
      margin-left: 25px;
    `;
    return (
      <div className="about-contact-form">
        <div className="row">
          <div className="col-md-12">
            <p>
              {contact.email !== "" ? (
                <>
                  <span className="font-brandon">
                    <b>Email</b>:{" "}
                    <a href={`mailto:${contact.email}`}>{contact.email}</a>
                  </span>
                  <br />
                </>
              ) : (
                ""
              )}
              {contact.phone !== "" ? (
                <>
                  <span className="font-brandon">
                    <b>Phone</b>: {contact.phone}
                  </span>
                  <br />
                </>
              ) : (
                ""
              )}
              <Link onClick={() => window.Tawk_API.toggle()}>
                <b>{contact.tag_line}</b>
              </Link>
            </p>
          </div>
        </div>
        <form onSubmit={this.onFormSubmit}>
          <div className="row">
            <div className="col-md-6">
              <TextFieldGroup
                type="text"
                name="first_name"
                className="form-control"
                id="firstName"
                placeholder="First Name *"
                value={first_name}
                error={errors.first_name}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <TextFieldGroup
                type="text"
                name="last_name"
                className="form-control"
                id="last_name"
                placeholder="Last Name *"
                value={last_name}
                error={errors.last_name}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <TextFieldGroup
                type="text"
                name="email"
                className="form-control"
                id="email"
                placeholder="Email Address *"
                value={email}
                error={errors.email}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <TextFieldGroup
                type="text"
                name="phone"
                className="form-control"
                id="phone"
                placeholder="Phone Number"
                value={phone}
                error={errors.phone}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextFieldGroup
                type="text"
                name="subject"
                className="form-control"
                id="subject"
                placeholder="Subject *"
                value={subject}
                error={errors.subject}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextAreaGroup
                type="text"
                name="message"
                className="form-control"
                id="message"
                placeholder="Message *"
                value={message}
                error={errors.message}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {errors.success_message ? (
                <div class="alert alert-success" role="alert">
                  {errors.success_message}
                </div>
              ) : (
                ""
              )}
              <div className="form-btn">
                <button className="btn btn-primary" disabled={loading}>
                  {!loading ? (
                    "Submit"
                  ) : (
                    <ClipLoader
                      css={override}
                      size={20}
                      color={"#3c3c3c"}
                      loading={loading}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors.error,
});

const mapDispatchToProps = { submitContactUs };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactUs));
