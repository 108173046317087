import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../store/actions/authActions";

class UserSidebar extends Component {
  logout = () => {
    this.props.logoutUser();
    this.props.history.push("/");
  };
  render() {
    return (
      <ul>
        <Link to="/my-orders">
          <li className="active">
            <img alt="" src="images/udb-icon-order.png" /> My Orders
          </li>
        </Link>
        <Link to="/my-profile">
          <li>
            <img alt="" src="images/udb-icon-edit.png" /> Edit Profile
          </li>
        </Link>
        <Link to="/change-password">
          <li>
            <img alt="" src="images/udb-icon-cpass.png" /> Change Password
          </li>
        </Link>
        <Link onClick={this.logout}>
          <li>
            <img alt="" src="images/udb-icon-lout.png" /> Logout
          </li>
        </Link>
      </ul>
    );
  }
}

export default connect(null, { logoutUser })(withRouter(UserSidebar));
