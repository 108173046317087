import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { setRedirectTo } from "../../store/actions/redirectToActions";

const UserRoute = ({ component: Component, auth, setRedirectTo, ...rest }) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  if (!auth.isAuthenticated) {
    setRedirectTo(currentRoute);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
UserRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  redirectTo: state.redirectTo,
});
const mapDispatchToProps = { setRedirectTo };
export default connect(mapStateToProps, mapDispatchToProps)(UserRoute);
