import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { API_URL } from "../../store/actions/types";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      redirectTo: "",
      email: "",
      loading: false,
      subscribe_message: "",
    };
  }
  gotoTab = async (tab) => {
    if (this.props.redirectToTab) {
      this.props.redirectToTab(tab);
    } else {
      this.props.history.push(`/about/${tab}`);
      // await this.setState({ redirectTo: `/about/${tab}` });

      // alert(this.state.redirectTo);
    }
    window.scrollTo(0, 0);
  };
  redirectToExternalURL = (url) => {
    window.open(url);
  };
  subscribe = (email) => {
    this.setState({ loading: true });
    axios
      .post(API_URL + "/email-subscribe", { email })
      .then((res) => {
        if (res.data.success === "true") {
          this.setState({
            loading: false,
            subscribe_message: res.data.message,
            email: "",
          });
        } else {
          this.setState({
            loading: false,
            subscribe_message: res.data.message,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          subscribe_message: err.message,
        });
      });
  };
  handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      this.subscribe(this.state.email);
    }
  };
  render() {
    const { loading } = this.state;
    const { setting } = this.props;
    //redirectTo
    const override = css`
      display: block;
      margin: 25px auto;
    `;
    // if (redirectTo !== "") {
    //   return <Redirect to={redirectTo} />;
    // } else {

    // }
    return (
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-8">
                  <div className="ftr-intro">
                    <img
                      src="/images/SantaCruzLogo.png"
                      className="img-fluid"
                      style={{ width: "166px" }}
                      alt=""
                    />
                    <p>
                      I would like to receive communications about Santa Cruz
                      products, events and matters of cultural interest.
                    </p>
                    <div className="form-group col-md-10">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        id="subscribeEmail"
                        placeholder="Email Address"
                        value={this.state.email}
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                        onKeyPress={this.handleKeyPressed}
                      />
                      <ClipLoader
                        css={override}
                        size={25}
                        color={"#3c3c3c"}
                        loading={loading}
                      />
                      <button
                        type="button"
                        className="btn btn-default tbox-arrow"
                        onClick={() => this.subscribe(this.state.email)}
                      >
                        <img src="/images/ftr-box-arrow.png" alt="" />
                      </button>
                      <div className="text-green">
                        {this.state.subscribe_message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4">
                  <div className="ftr-links ftr-links-column-2">
                    <h3>About</h3>
                    <ul>
                      <li>
                        <Link onClick={() => this.gotoTab("process")}>
                          Our process
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => this.gotoTab("careers")}>
                          Careers
                        </Link>
                      </li>
                      <li>
                        <Link to="/store-locator">Find Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ftr-links">
                    <h3>Orders &amp; Support</h3>
                    <ul>
                      <li>
                        <Link onClick={() => this.gotoTab("contact-us")}>
                          Contact us
                        </Link>
                      </li>
                      <li>
                        <Link onClick={() => this.gotoTab("faq")}>FAQ</Link>
                      </li>
                      {/* <li>
                        <Link onClick={() => this.gotoTab("process")}>
                          Lab results
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="ftr-links">
                    <h3>Social Media</h3>
                    <ul>
                      <li>
                        <Link
                          onClick={() => {
                            this.redirectToExternalURL(
                              "https://www.instagram.com/santacruzbrands/"
                            );
                          }}
                        >
                          Instagram
                        </Link>
                      </li>
                      <li>
                        <Link
                          onClick={() => {
                            this.redirectToExternalURL(
                              "https://www.facebook.com/santacruzbrands"
                            );
                          }}
                        >
                          Facebook
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="ftr-dn">
                <p>
                  {setting.footerText} |{" "}
                  <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
                  <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  setting: state.setting,
});
export default connect(mapStateToProps, null)(withRouter(Footer));
