import React, { Component } from "react";
import { Link } from "react-router-dom";

class FAQItem extends Component {
  render() {
    const { faq, open } = this.props;
    return (
      <div className="card">
        <div id={`heading${faq.id}`} className="card-header shadow-sm border-0">
          <h6 className="mb-0 font-weight-bold">
            <Link
              data-toggle="collapse"
              data-target={`#collapse${faq.id}`}
              // aria-expanded={`${open ? true : false}`}
              aria-controls={`collapse${faq.id}`}
              aria-expanded={open}
              className={`d-block position-relative text-dark text-uppercase collapsible-link py-2${
                !open ? " collapsed" : ""
              }`}
            >
              {faq.question}
            </Link>
          </h6>
        </div>
        <div
          id={`collapse${faq.id}`}
          aria-labelledby={`heading${faq.id}`}
          data-parent="#accordionExample"
          className={`collapse${open ? " show" : ""}`}
        >
          <div className="card-body p-5">
            <p className="font-weight-light m-0">{faq.answer}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQItem;
