import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Anchor from "./Anchor";

class FromPlant extends Component {
  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <div className="lit-intro">
        <div className="lit-intro-box">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <Fade bottom>
                  <h1>{content.sec_7_heading}</h1>
                  <p>
                    {ReactHtmlParser(content.sec_7_desc.replace("\r\n", ""))}
                  </p>
                  <Anchor
                    linkType={content.sec_7_link_type}
                    link={content.sec_7_link}
                    text={content.sec_7_btn_title}
                  />
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default FromPlant;
