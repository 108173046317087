import axios from "axios";
import { setAuthToken } from "../../functions/setHeaders";
// import isEmpty from "../../functions/is-empty";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_REDIRECT_TO,
  API_URL,
} from "./types";

//Login User
export const loginUser = (userData, history, startStopLoading, redirectTo) => (
  dispatch
) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/login", userData)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      //Set token to Auth Header
      setAuthToken(token);
      //Decode token to get user data
      const decoded = jwt_decode(token);
      dispatch(setCurrentUser(decoded));
      startStopLoading(false);
      if (redirectTo !== "") {
        dispatch({
          type: SET_REDIRECT_TO,
          payload: "",
        });
        history.push(redirectTo);
      } else {
        history.push("/my-orders");
      }
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err.message,
      });
      startStopLoading(false);
    });
};

//Regisgter User
export const registerUser = (userData, history, startStopLoading) => (
  dispatch
) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/register", userData)
    .then((res) => {
      startStopLoading(false);
      if (res.data.success === "true") {
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        //Set token to Auth Header
        setAuthToken(token);
        //Decode token to get user data
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
        history.push("/dashboard");
      } else {
        alert("Something went wrong");
      }
    })
    .catch((err) => {
      startStopLoading(false);
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

//Update User
export const updateUser = (userProfile, startStopLoading) => (dispatch) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/update-profile", userProfile)
    .then((res) => {
      if (res.data.success === "true") {
        dispatch({
          type: GET_ERRORS,
          payload: {},
        });
        dispatch({
          type: GET_ERRORS,
          payload: { error: { message: res.data.message } },
        });
        const { token } = res.data;
        localStorage.setItem("jwtToken", token);
        //Set token to Auth Header
        setAuthToken(token);
        //Decode token to get user data
        const decoded = jwt_decode(token);
        dispatch(setCurrentUser(decoded));
      } else {
        alert("Something went wrong");
      }
      startStopLoading(false);
    })
    .catch((err) => {
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
      startStopLoading(false);
    });
};

export const changePassword = (data, clearFields, startStopLoading) => async (
  dispatch
) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/change-password", data)
    .then((res) => {
      startStopLoading(false);
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      if (res.data.success === "true") {
        clearFields();

        dispatch({
          type: GET_ERRORS,
          payload: {
            error: {
              message: "Password has been changed.",
            },
          },
        });
      }
    })
    .catch((err) => {
      startStopLoading(false);
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const forgotPassword = (data, history, startStopLoading) => async (
  dispatch
) => {
  startStopLoading(true);
  axios
    .post(API_URL + "/forget-password", data)
    .then((res) => {
      startStopLoading(false);
      dispatch({
        type: GET_ERRORS,
        payload: {
          error: {
            success_message: "Please check your email to reset your password.",
          },
        },
      });
    })
    .catch((err) => {
      startStopLoading(false);
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

export const resetPassword = (data, startStopLoading, clearFields) => async (
  dispatch
) => {
  startStopLoading(true);
  axios
    .put(`${API_URL}/reset-password/${data.reset_code}`, {
      email: data.email,
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    })
    .then((res) => {
      startStopLoading(false);
      if (res.data.success === "true") {
        dispatch({
          type: GET_ERRORS,
          payload: {
            error: {
              success_message: "You password has been reset successfully.",
            },
          },
        });
        clearFields();
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: res.response.data.error,
        });
      }
    })
    .catch((err) => {
      startStopLoading(false);
      if (err.response.status === 404) {
        alert("Invalid API URL");
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      }
    });
};

// Log out user
export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};

// Set user login state
export const setUserState = (user, token) => (dispatch) => {
  //Set token to Auth Header
  setAuthToken(token);
  dispatch(setCurrentUser(user));
};

// Set logged in user
export const setCurrentUser = (userInfo) => {
  return {
    type: SET_CURRENT_USER,
    payload: userInfo,
  };
};

export const hideErrors = () => (dispatch) => {
  dispatch(clearErrors());
};
export const clearErrors = () => {
  return {
    type: GET_ERRORS,
    payload: { error: {} },
  };
};
