import { SET_SETTING } from "./types";

export const setSetting = (showCart, showLogin, footerText) => async (
  dispatch
) => {
  const payload = { showCart, showLogin, footerText };

  await dispatch({
    type: SET_SETTING,
    payload,
  });
};
