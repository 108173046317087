import { SET_AGE_VERIFICATION } from "./types";

export const eligible = (eligible, history) => async (dispatch) => {
  const payload = { verified: true, eligible };

  await dispatch({
    type: SET_AGE_VERIFICATION,
    payload,
  });

  localStorage.setItem("SantaCruzAgeVerified", JSON.stringify(payload));
  // if (eligible) history.push("/");
  // else history.push("/not-eligible");
};
