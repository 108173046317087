import React, { Component } from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Pagination from "react-js-pagination";
import axios from "axios";
import { API_URL } from "../../store/actions/types";
import FAQItem from "./FAQItem";

export default class FAQ extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      faqs: [],
      ttotalRecords: 0,
      recordPerPage: 0,
      currentPage: 1,
    };
  }
  componentDidMount() {
    this.getFAQs(1);
  }
  getFAQs = (page) => {
    this.setState({
      loading: true,
      currentPage: page,
    });

    axios(API_URL + "/faqs?page=" + page)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            faqs: res.data.faqs.data,
            totalRecords: res.data.faqs.total,
            recordPerPage: res.data.faqs.per_page,
            loading: false,
          });
        } else {
          alert("Could not get faqs");
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        alert("Something went wrong.");
        this.setState({
          loading: false,
        });
      });
  };
  render() {
    const {
      loading,
      faqs,
      totalRecords,
      recordPerPage,
      currentPage,
    } = this.state;
    const override = css`
      display: inline-block;
      margin-left: 25px;
    `;
    return (
      <div className="row">
        <div className="col-lg-12 mx-auto abt-faq">
          {/* Accordion */}
          <div id="accordionExample" className="accordion shadow">
            {/* Accordion item 1 */}
            {faqs.map((faq, index) => (
              <FAQItem faq={faq} key={index} open={index === 0} />
            ))}
          </div>
          {totalRecords > recordPerPage ? (
            <Pagination
              innerClass="pagination faqs-pagination"
              itemClass="page"
              activePage={currentPage}
              itemsCountPerPage={recordPerPage}
              totalItemsCount={totalRecords}
              pageRangeDisplayed={10}
              onChange={this.getFAQs}
              activeLinkClass="selected"
            />
          ) : (
            ""
          )}
          <ClipLoader
            css={override}
            size={25}
            color={"#3c3c3c"}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}
