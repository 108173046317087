import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import Reveal from "react-reveal/Reveal";
import Anchor from "./Anchor";

class Technology extends Component {
  render() {
    const { content, loading } = this.props;
    return !loading ? (
      <div className="tbt">
        <div className="container-fluid tbt-full">
          <div className="row">
            <div className="col-lg-6 tbt-left nlrp">
              <Fade bottom duration={800}>
                <div className="content-center">
                  <h1>{content.sec_3_line_1}</h1>
                  <h5>{ReactHtmlParser(content.sec_3_line_2)}</h5>
                  {ReactHtmlParser(content.sec_3_desc)}
                  <Anchor
                    linkType={content.sec_3_link_type}
                    link={content.sec_3_link}
                    text={content.sec_3_text}
                  />
                </div>
              </Fade>
            </div>

            <div className="col-lg-6 tbt-right nlrp">
              <Reveal>
                <img src={content.sec_3_img} className="img-fluid" alt="" />
              </Reveal>
            </div>
          </div>
        </div>

        <div className="container-fluid tbt-res">
          <div className="row">
            <div className="col-lg-6 tbt-right nlrp">
              <Reveal>
                <img src={content.sec_3_img} className="img-fluid" alt="" />
              </Reveal>
            </div>
            <div className="col-lg-6 tbt-left nlrp">
              <Fade bottom duration={800}>
                <div className="content-center">
                  <h1>{content.sec_3_line_1}</h1>
                  <h5>{ReactHtmlParser(content.sec_3_line_2)}</h5>
                  {ReactHtmlParser(content.sec_3_desc)}
                  <Anchor
                    linkType={content.sec_3_link_type}
                    link={content.sec_3_link}
                    text={content.sec_3_text}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    );
  }
}

export default Technology;
