import React from "react";
import classnames from "classnames";

const TextAreaGroup = ({
  name,
  id,
  placeholder,
  value,
  error,
  info,
  onChange,
  disabled,
  readOnly,
  extraClassName,
  rows,
}) => {
  return (
    <div className={`form-group${" " + extraClassName}`}>
      <textarea
        className={classnames("form-control", {
          "is-invalid": error,
        })}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly}
        rows={rows}
      ></textarea>
      {info && <small className="form-text text-muted">{info}</small>}
      {error && (
        <div className="invalid-feedback">
          <small>{error}</small>
        </div>
      )}
    </div>
  );
};

TextAreaGroup.defaultProps = {
  id: "",
  extraClassName: "",
  readOnly: false,
  disabled: false,
  rows: 5,
};

export default TextAreaGroup;
