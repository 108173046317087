import { SET_STATE_ID } from "./types";
import { setStateIdHeader } from "../../functions/setHeaders";

export const setStateId = (stateId, stateName) => async (dispatch) => {
  const payload = { stateId, stateName };

  setStateIdHeader(stateId);

  await dispatch({
    type: SET_STATE_ID,
    payload,
  });

  localStorage.setItem("SantaCruzUsState", JSON.stringify(payload));
};
